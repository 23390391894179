import UIkit from 'uikit'
import Icons from 'uikit/dist/js/uikit-icons'
import WebFont from 'webfontloader'

import '@/plugins/sentry'

// Configure UIkit to use the Icons plugin
UIkit.use(Icons)

// Web fonts configuration
WebFont.load({
  typekit: {
    id: 'ooe8eql',
  },
})

import.meta.glob(['../img/**/*'])
